.container {
    width: calc(100% - 20px);
    height: 100px;
    border: 2px solid gray;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: row;    
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.container:hover {
    opacity: 0.7;
}


.image {
    width: 50px;
    height: 50px;
    margin: 0;
    margin-left: 25px;
    margin-top: 25px;    
    background: url('./icon.png');
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center center;
}

.info {
    width: calc(100% - 98px);
    height: 98px;
    display: flex;
    flex-direction: column;    
}

.selected {
    background-color: #2f2f2f;
    border: 2px solid #93CAED;
}

.label {
    width: 100%;
    height: auto;
    display: table-cell;    
    line-height: auto;
    margin-left: 15px;
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    /* vertical-align: middle;     */
}

.download {    
    background: url('./media-small.png');
    background-position: -96px -64px;
    
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    cursor: pointer; 
    display: inline-block;
}

.share {
    background: url('./share.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    cursor: pointer; 
    display: inline-block;
}

.flowControl {
    display: flex;
    flex-direction: column;
}

.flow {
    height: 16px;
    min-height: 16px;
    display: inline-block;
}