@import url("./style.css");

/*Elements*/

table {
    border-spacing: 0;
    border-collapse: collapse
}


body::-webkit-scrollbar {
    display: none;
}

/*IDs*/
#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
    align-content: center;
    position: absolute;
}

#modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 100;
}

#logo {
    position: absolute;
    top: 10px;
    left: 10px;
    max-width: min(512px, 60%);
    max-height: min(512px, 60%);
}

#musicPlayer {
    width: 100%;
    height: 100%;
    margin: 0;
    display: block;
}
#logoContainer {
    position: absolute;
    top: 126px;    
    left: 0px;
    width: 100%;
    height: calc(100% - 226px);
    display: block;
    align-content: center;
}
#bbbLogo {
    background: url(./media-logo.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: max(512px, 80vmin) max(512px, 80vmin);
    width: 100%;
    height: 100%;
}

#loading  {
    position: absolute;
    top: calc(30% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    z-index: 10;
    background: url('./icon.png');
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 448px) {
    #logoContainer {
        position: absolute;
        top: 96px;
        left: 0px;
        width: 100%;
        height: calc(100% - 174px);
        display: block;
        align-content: center;
    }
}

@media only screen and (max-width: 336px) {
    #logoContainer {
        position: absolute;
        top: 62px;
        left: 0px;
        width: 100%;
        height: calc(100% - 122px);
        display: block;
        align-content: center;
    }
}