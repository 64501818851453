/* Volume */
#volume {
    width: 50px;
    height: 200px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    touch-action: none;
    -webkit-user-select: none;
    user-select:none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 1);
    display: none;
    z-index: 1000;
  }

  #barEmpty {
    position: absolute;
    width: 90%;
    height: 100%;
    left: 5%;
    opacity: 0.5;
    box-shadow: none;
    cursor: pointer;
    background-color: #93CAED;
  }
  #barFull {
    position: absolute;
    width: 15px;
    left: 17.5px;
    background-color: rgba(255, 255, 255, 0.7);
  }
  #sliderBtn {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 0%;
    margin: -25px auto;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 1px 1px 5px rgba(46, 46, 46, 0.33);
    border-radius: 25px;
    cursor: pointer;
  }

  .bar {
    position: absolute;
    top: 50%;
    left: 5%;
    margin: -5px auto;
    height: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
    visibility: hidden;
  }

  /* Fade-In */
.fadeout {
  -webkit-animation: fadeout 0.5s;
  -ms-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}
.fadein {
  -webkit-animation: fadein 0.5s;
  -ms-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.volumeHidden {
  display: none;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}