#downloadContainer {
    margin: auto;
    width: min(300px, 100%);
    height: min(200px, 100%);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.98);
    display: block;
    flex-direction: column;
    z-index: 101;
    border: 2px solid #93CAEDCC;
    position: absolute;
    left: calc(50% - min(300px, 100%)/2);
    top: calc(50% - min(200px, 100%) / 2);
}

.downloadLink {
    width: 100%;
    height: 50%;
    line-height: 100px;
    font-size: 30px;
    z-index: 102;
    color: black;
    text-align: center;
    font-weight: bold;
    vertical-align: middle;   
}

.link {
    cursor: pointer;
    height: auto;
};