#mediaContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    background-color: #151515;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #93CAED;
    z-index: 100;
}

#mediaController {
    flex-direction: row;
    /* display: block; */
    width: auto;
    height: 100%;
    margin: auto;
    /* align-self: center; */
}

#mediaRandom {
    background: url('./media.png') -64px -64px;
}

#mediaPrevious {
    background: url('./media.png') 128px 0px;
    transform: scaleX(-1);
}

#mediaPlay {
    background: url('./media.png') 0px 0px;
}

#mediaPause {
    background: url('./media.png') -64px 0px;
}

#mediaLoading {
    background: url('./media.png') -192px -64px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

#mediaNext {
    background: url('./media.png') 128px 0px;
}

#mediaRepeatNone {
    background: url('./media.png') -192px 0px;
}

#mediaRepeatAll {
    background: url('./media.png') -192px 0px;
}

#mediaRepeatOne {
    background: url('./media.png') 0px -64px;
}

#mediaVolume {
    background: url('./media.png') -128px -64px;
}

#menuButton {
    background: url('./media.png') -0px -128px;
}

.mediaButton {
    width: 64px;
    height: 64px;
    background-size: 64px;
    display: inline-block;
    cursor: pointer;
}

.disabled {
    filter: brightness(0.5);
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 448px) {

    #mediaRandom {
        background: url('./media-medium.png') -48px -48px;
    }

    #mediaPrevious {
        background: url('./media-medium.png') 96px 0px;
        transform: scaleX(-1);
    }

    #mediaPlay {
        background: url('./media-medium.png') 0px 0px;
    }

    #mediaPause {
        background: url('./media-medium.png') -48px 0px;
    }

    #mediaLoading {
        background: url('./media-medium.png') -144px -48px;
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
    }

    #mediaNext {
        background: url('./media-medium.png') 96px 0px;
    }

    #mediaRepeatNone {
        background: url('./media-medium.png') -144px 0px;
    }

    #mediaRepeatAll {
        background: url('./media-medium.png') -144px 0px;
    }

    #mediaRepeatOne {
        background: url('./media-medium.png') 0px -48px;
    }

    #mediaVolume {
        background: url('./media-medium.png') -96px -48px;
    }

    #menuButton {
        background: url('./media-medium.png') 0px -96px;
    }

    .mediaButton {
        width: 48px;
        height: 48px;
        background-size: 48px;
        display: inline-block;
        cursor: pointer;
    }
}

@media only screen and (max-width: 336px) {
    #mediaRandom {
        background: url('./media-small.png') -32px -32px;
    }

    #mediaPrevious {
        background: url('./media-small.png') 64px 0px;
        transform: scaleX(-1);
    }

    #mediaPlay {
        background: url('./media-small.png') 0px 0px;
    }

    #mediaPause {
        background: url('./media-small.png') -32px 0px;
    }

    #mediaLoading {
        background: url('./media-small.png') -128px -32px;
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
    }

    #mediaNext {
        background: url('./media-small.png') 64px 0px;
    }

    #mediaRepeatNone {
        background: url('./media-small.png') -96px 0px;
    }

    #mediaRepeatAll {
        background: url('./media-small.png') -96px 0px;
    }

    #mediaRepeatOne {
        background: url('./media-small.png') 0px -32px;
    }

    #mediaVolume {
        background: url('./media-small.png') -64px -32px;
    }

    #menuButton {
        background: url('./media-small.png') 0px -64px;
    }

    .mediaButton {
        width: 32px;
        height: 32px;
        background-size: 32px;
        display: inline-block;
        cursor: pointer;
    }
}