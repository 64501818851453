#playlist::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar {
    display: none;
}

/** Mobile or default*/
#playlistContainer {
    width: 100%;
    height: calc(100% - 230px);
    position: absolute;
    left: 0;
    top: 128px;
    background-color: black;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;    
    z-index: 100;
}

#playlist {
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: table;
    height: 38px;
}

#playlistBody {
    display: table-row-group;
}

#playlistToolbar {
    /* margin-left: 10px;
    margin-right: 10px; */
    display: flex;
    flex-direction: row;
    color: white;
    background-color: #efefef;      
    border: 2px solid #93CAED;
    /* border-radius: 8px;  */
}

.toolbarLabel {
    line-height: 38px;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #efefef;    
    padding-left: 10px;
    padding-right: 10px;    
}

.item {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    color: black;
}

.playlistHeader {
    border-radius: 5px;
    background-color: gray;
    margin: 10px;
    text-align: center;
    height: 3.4vh;
    font-size: 3.2vmin;
    line-height: 3.4vh;
    font-weight: bold;
    cursor: pointer;
    color: white;
}

.playlistRow {
    width: 100%;
    display: table-row;
    color: white;
    /* border-bottom: 1px solid white; */
}

.playlistColumn {
    text-align: center;
    font-size: 32px;
}

.playlistButton {
    cursor: pointer;
}

.even {
    background-color: rgba(75, 75, 75, 0.8);
}

.selected {
    background-color: rgba(255, 255, 255, 0.8);
}

#playlistContainer::-webkit-scrollbar {
    display: none;
}

.download {    
    background: url('./media-small.png');
    background-position: -96px -64px;    
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    cursor: pointer; 
    display: inline-block;
    filter: invert();
}

@media only screen and (max-width: 448px) {
    #playlistContainer {
        width: 100%;
        height: calc(100% - 148px);
        position: absolute;
        left: 0;
        top: 100px;
        background-color: black;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;    
        z-index: 100;
    }

    .playlistColumn {
        text-align: center;
        font-size: 24px;
    }
}

@media only screen and (max-width: 336px) {
    #playlistContainer {
        width: 100%;
        height: calc(100% - 98px);
        position: absolute;
        left: 0;
        top: 64px;
        background-color: black;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;    
        z-index: 100;
    }

    .playlistColumn {
        text-align: center;
        font-size: 16px;
    }
}