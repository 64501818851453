pre {
    margin: auto;
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
}

#titleContainer {
    position: absolute;
    left: 0;
    top: 0;
    height: 124px;
    width: 100%;
    background-color: #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #93CAED;
    z-index: 100;
}

#titleDisplay {
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-wrap: break-word;
    display: flex;
}

.trackTitle {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;    
    height: 100%;
    margin-top: 10px;
    margin-bottom: auto;    
    color: white;
    text-align: center;
    vertical-align: text-top;
}

.trackSize {
    font-size: 18px;
}

@media only screen and (max-width: 448px) {
    #titleContainer {
        position: absolute;
        left: 0;
        top: 0;
        height: 96px;          
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #titleDisplay {
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow-wrap: break-word;
        display: flex;
        left: 48px;
        right: 48px;
    }

    .track {
        width: 100%;
        height: 48px;
        font-size: 24px;
        color: white;
        text-align: center;
    }

    .trackSize {
        font-size: 14px;
    }   
}

@media only screen and (max-width: 336px) {
    #titleContainer {
        position: absolute;
        left: 0;
        top: 0;
        height: 60px;            
        display: flex;
        align-items: center;
        justify-content: center;
    }
   
    #titleDisplay {
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow-wrap: break-word;
        display: flex;
        left: 32px;
        right: 32px;
    }  

    .track {
        width: 100%;
        height: 32px;
        font-size: 16px;
        color: white;
        text-align: center;
    }  

    .trackSize {
        font-size: 12px;
    }
}