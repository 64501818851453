#timeContainer {
    position: absolute;
    left: 0;
    bottom: 70px;
    height: 30px;
    width: 100%;
    /* background-color: red; */
    margin: 0;
}

#emptyBar {
    background-image: url('./bar-fg.png');
    background-size: 100% 100%;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.8);
}

#progressBar {
    position: absolute;
    background-image: url('./bar-bg.png');
    background-size: 100% 100%;
    margin: 0;
    left: 0;
    top: 0%;
    height: 100%;
}

#timeLabel {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;    
    font-weight: bold;
    text-align: center;
    color: black;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
}
#elapsedLabel {
    position: absolute;    
    margin: 0;
    right: 0px;
    top: 0px;
    width: 40px;
    font-weight: bold;
    text-align: center;
    color: black;
    
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;    
    cursor: pointer;
}

.timeLabel {
    height: calc(100% - 2px);
    line-height: 28px;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 448px) {
    #timeContainer {
        position: absolute;
        left: 0;
        bottom: 56px;
        height: 22.5px;
        width: 100%;
        /* background-color: red; */
        margin: 0;
    }
    .timeLabel {
        height: calc(100% - 2px);
        line-height: 20px;
        font-size: 16px;
        cursor: pointer;
    }    
}

@media only screen and (max-width: 336px) {
    #timeContainer {
        position: absolute;
        left: 0;
        bottom: 38px;
        height: 22.5px;
        width: 100%;
        /* background-color: red; */
        margin: 0;
    }
    .timeLabel {
        height: calc(100% - 2px);
        line-height: 20px;
        font-size: 16px;
        cursor: pointer;
    }
}